import React from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
export default function SideBar({ sideBarRender, setSideBarRender }) {
  const handleClose = () => setSideBarRender({ isRender: false });

  const navLinks = [
    { text: "Home", url: "https://www.ebonow.com/" },
    { text: "Privacy Policy", url: "https://www.ebonow.com/privacy" },
    { text: "Terms And Conditions", url: "https://www.ebonow.com/terms" },
    { text: "Cancellation And Refunds", url: "https://www.ebonow.com/cancellation" },
    { text: "FAQs", url: "https://www.ebonow.com/faqs" },
    { text: "Contact Us", url: "https://www.ebonow.com/help" },
  ];

  if (!sideBarRender.isRender) {
    return <></>;
  }

  return (
    <div className="fixed top-0 right-0 text-[#171717] tracking-[.6px] left-0 bottom-0 bg-[#000000af]">
      <motion.div
        initial={{ x: 200 }}
        animate={{ x: 0 }}
        className="fixed top-0 right-[-3rem] bottom-0 md:w-[20rem] w-[18rem] bg-[white]"
      >
        <div className="flex gap-4 pl-3 pt-5  ">
          <RxCross2
            onClick={handleClose}
            className=" text-[1.725rem] relative top-1 md:top-0 "
          />
          <Link to="https://www.ebonow.com/">
            <h1
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
              }}
              className=" xl:text-[2.6rem] text-[1.725rem] relative lg:text-[2.3rem] flex justify-center items-center text-[#2136d4] font-[800]  "
            >
              ebo
            </h1>
          </Link>
        </div>
        <ul className="flex flex-col  text-[.825rem] border-t border-[#c4c4c4] font-[600] md:text-[1rem] gap-2 pl-4 pt-6 mt-4 ">
          {navLinks.map((link, idx) => {
            return (
              <motion.li whileHover={{x:5}} key={idx} className=" whitespace-nowrap  ">
                <Link to={link.url}>{link.text}</Link>
              </motion.li>
            );
          })}
        </ul>
      </motion.div>
    </div>
  );
}
