import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "./layots/DefaultLayout";
import BlogsPageSkeleton from "./pages/blogsPage/BlogsPageSkeleton";
const BlogsPage = lazy(() => import("./pages/blogsPage/BlogsPage"));
const SingleBlogPage = lazy(() => import("./pages/blogPage/SingleBlogPage"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<BlogsPageSkeleton />}>
              <BlogsPage />
            </Suspense>
          }
        />
        <Route
          path="/blog/:title/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <SingleBlogPage />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<BlogsPageSkeleton />}>
              <BlogsPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

const Spinner = () => {
  return (
    <div className=" flex items-center h-[90vh] justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};

export default App;
