import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import SideBar from "./SideBar";

function BlogNavbar() {
  const [sideBarRender, setSideBarRender] = useState({
    isRender: false,
  });
  return (
    <nav
      style={{ position: "-webkit-sticky" }}
      className="navbar bg-white z-50  shadow-sm sticky top-0 w-full"
    >
      <div className="flex-1">
        {/* <img
          src="/img/celebration_notebook.svg"
          className="h-8 w-20 md:h-12 lg:h-14"
          alt="celebration_notebook_logo"
        /> */}
        <h2
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className="text-[1.25rem] inline-block tracking-[-2px] leading-[1.2rem] px-2 text-[#171717] font-[900]"
        >
          <span className="block">celebration</span>
          <span className="relative left-[1.1rem] ">
            not<span className="text-[#2136d4]">ebo</span>ok
          </span>
        </h2>
        <span className="h-10 mx-2 border"></span>
        <Link to="https://www.ebonow.com/">
          <h1
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className=" xl:text-[2.6rem] relative text-[1.725rem] lg:text-[2.3rem] flex justify-center items-end text-[#2136d4] font-[800]  "
          >
            ebo
          </h1>
        </Link>
      </div>
      <div className="flex-none">
        <div className="md:text-lg mx-4 flex gap-4 items-center">
          <Link
            to="https://www.ebonow.com"
            className="hidden md:inline lg:inline xl:inline"
          >
            Home
          </Link>
          <Link
            to="https://www.ebonow.com/help"
            className="hidden md:inline lg:inline xl:inline"
          >
            Contact Us
          </Link>
          <Link to="/blogs" className="hidden md:inline lg:inline xl:inline">
            Blogs
          </Link>
          <FiMenu
            className=" h-6 w-6 cursor-pointer"
            onClick={() => {
              setSideBarRender({ isRender: true });
            }}
          />
          <SideBar
            setSideBarRender={setSideBarRender}
            sideBarRender={sideBarRender}
          />
        </div>
      </div>
    </nav>
  );
}

export default memo(BlogNavbar);
