import React,{memo} from 'react'

function FooterBottom() {
  return (
    <div className='w-full font-[600] text-[.725rem] md:text-[.825rem]  text-center'>
        <p>© ebo Solutions Pvt. Ltd. | Satra Plaza, Navi Mumbai - 400703</p>
    </div>
  )
}


export default memo(FooterBottom)
