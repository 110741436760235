import React, { memo } from "react";
import "./Footer.css";
import FooterBottom from "./FooterBottom";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import FooterAboutUs from "./FooterAboutUs";

function Footer() {
  return (
    <footer
      className="flex flex-col items-center
     bg-[#F1F6FF]"
    >
      <div className="ebo-footer">
        <FooterLeft />
        <FooterRight />
      </div>
      <FooterBottom />
      <FooterAboutUs />
    </footer>
  );
}

export default memo(Footer);
