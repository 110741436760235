import React from "react";

export default function BlogsPageSkeleton() {
  const cards = new Array(6).fill(1);
  return (
    <div className=" border-box   z-50 bg-white">
      <div className=" mt-[1rem] md:mt-[1rem] border-box">
        <div className=" border-box my-4 mx-auto w-11/12 text-black flex  flex-col space-y-6 ">
          <div className="flex h-24  border-box justify-center mb-4 items-end">
            <h2
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
              }}
              className="text-[2.5rem] inline-block tracking-[-2px] leading-[2.2rem] text-[#171717] font-[900]"
            >
              <span className="block">celebration</span>
              <span className="relative left-[2.6rem] ">
                not<span className="text-[#2136d4]">ebo</span>ok
              </span>
            </h2>
            <p className="text-[#2136d4] relative left-[1.25rem] text-[1.25rem] font-[900] inline-block">
              {" "}
              The Blog
            </p>
          </div>
          <div className=" flex flex-wrap items-center justify-center gap-8 border-box">
            {cards.map((curr) => {
              return (
                <div
                  key={curr}
                  className="ebo_skeleton w-64 h-64 md:h-72 md:w-72 lg:h-96 lg:w-96 rounded-lg"
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
